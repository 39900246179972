//require('_themeCore/0-0_app/js/index.js');
import {index} from './../../0-0_app/js/index.js';
import {initDlMenu} from './../../0-0_app/js/index.js';
// import {initMagnificPopup} from './../../0-0_app/js/index.js';
// import {switchSenderType} from './../../0-0_app/js/index.js';
// import {switchSendInfoByType} from './../../0-0_app/js/index.js';
import {SchedulePlan} from './../../../themeComponents/timetables/js/index.js';
import {ajaxSubmitSearch} from './../../../themeComponents/search/js/index.js';
import {clearSearch} from "./../../../themeComponents/search/js/index";

/**
 * backbutton support
 *
 * @param ajaxDoc
 * @param cultureKey
 */
function historyPushState(ajaxDoc, cultureKey)
{
    let hashafter, linkid, ajaxurl, pagenum, key, filter, target, backajaxurl;
    if (window.history && window.history.pushState) {
        jQuery(window).on('popstate', function(e) {
            if (window.location.hash !== '' && window.location.hash !== 'undefined') {
                if(e.handled !== true) {
                    jQuery('body').isLoading({
                        'text': 'Načítání',    // loader CSS class
                        'tpl': '<span class="isloading-wrapper %wrapper%">%text%<\/span>'
                    });
                    hashafter = window.location.hash.substr(2);
                    linkid = hashafter.substr(0, hashafter.indexOf('&'));
                    if (linkid === '') {
                        linkid = hashafter;
                        ajaxurl = ajaxDoc + '&ajaxid=' + linkid;
                    }
                    else {
                        pagenum = urlParam('page');
                        key = urlParam('key');
                        filter = urlParam('filter');
                        ajaxurl = ajaxDoc + '?cultureKey=' + cultureKey + '&ajaxid=' + linkid + '&' + key + '=' + pagenum + '&hdfilter=' + filter;
                    }
                    target = '#loading';
                    jQuery('.ajaxlink').parent().removeClass('active');
                    if (e.originalEvent.state !== null) {
                        loadContent(ajaxurl, target);
                    }
                    jQuery('.alink_' + linkid).attr('id', 'active_link').parent().addClass('active');
                    e.handled = true;
                }
            }
            else {
                if(e.handled !== true) {
                    jQuery('body').isLoading({
                        'text': 'Načítání',    // loader CSS class
                        'tpl': '<span class="isloading-wrapper %wrapper%">%text%<\/span>'
                    });
                    backajaxurl = ajaxDoc + '&ajaxid=1';
                    loadContent(backajaxurl, target);
                    e.handled = true;
                }
            }
        });
    }
}

/**
 * Ajax Load Function
 *
 * @param ajaxurl
 * @param target
 * @param paramsLength
 * @param pagenum
 * @param pagevarLength
 * @param pagination
 * @param eventLink
 * @returns {boolean}
 */
function loadContent(ajaxurl, target, paramsLength, pagenum, pagevarLength, pagination = false, eventLink = false)
{
    pagination = pagination || false;
    pagevarLength = pagevarLength || 0;
    paramsLength = paramsLength || 0;

    let linkid, activelink, activePagelink, substrStartsFrom, paginationLink, parentMenuLink;
    let ajaxurlLength = ajaxurl.toString().length;
    let body = $('body');

    jQuery.ajax({
        type: 'POST',
        dataType: 'html',
        url: ajaxurl,
        cache: false,
        success: function(data){
            /* set data */
            jQuery(target).html(data);

            /* get linkid */
            linkid = ajaxurl.substr(24,10);
            linkid = linkid.split('&')[0];

            /* if it is request from pagination, get linkid by another way */
            if (pagination === true) {
                // substrStartsFrom = 30 + pagevarLength + 8; // 30 is length of 'ajaxcontent.html?cultureKey=cs', pagevarLength is length of custom part of url for example '&page21=1', 8 is length of '&ajaxid=',
                // if (parseInt(paramsLength) > 0) {
                //     ajaxurl = ajaxurl.slice(0, - paramsLength);
                // }
                // linkid = ajaxurl.substr(substrStartsFrom, 4);
                linkid = ajaxurl.split('ajaxid=')[1];
            }

            /* active menu link */
            activelink = jQuery('.ajaxlink.alink_' + linkid);
            jQuery('.ajaxlink').parent().removeClass('active');
            activelink.parent().addClass('active');
            activelink.parent().parent().parent().addClass('active');
            activelink.parent().parent().parent().parent().parent().addClass('active');

            /* active top menu link from sidebar menu */
            let rMenuItem = $(data).find('.rmenu li.first');
            let parentMenuId = rMenuItem.find('a').attr('data-attr-parent');
            if (typeof parentMenuId !== 'undefined') {
                parentMenuLink = body.find('.nav-item .ajaxlink.alink_' + parentMenuId);
                parentMenuLink.parent().addClass('active');
                parentMenuLink.parent().parent().parent().addClass('active');
            }

            /* active top menu link from sidebar events list and change crumbs */
            if (eventLink === true) {
                $(target).find('.rmenu li').removeClass('active');
                let eventsLinkItem = $(target).find('.rmenu li.first');
                if (typeof eventsLinkItem !== 'undefined') {
                    eventsLinkItem.addClass('active');
                }
                let crumbsItem = $(target).find('.breadcrumb .breadcrumb-item.active');
                if (typeof crumbsItem !== 'undefined') {
                    crumbsItem.html('akce');
                }
            }

            /* activate menu item home */
            body.find('a.alink_1').removeClass('home-active');
            if (ajaxurl === 'ajaxcontent.html&ajaxid=1') {
                let homeItem = body.find('.navbar .nav-item .alink_1');
                if (typeof homeItem !== 'undefined') {
                    homeItem.addClass('home-active');
                }
            }

            /* init main features */
            index();
            //clearSearch
            clearSearch();
            //Ajax Submit Search
            ajaxSubmitSearch('ajaxcontent.html');

            /* init schedule */
            if (($(data).find('.cd-schedule')).length) {
                let timetableEement = $('.cd-schedule');
                let scheduleObject = new SchedulePlan(timetableEement);
                scheduleObject.initSchedule();
            }

            /* init FB plugin */
            FB.XFBML.parse(document.getElementById('result'));

            /* hide loading and scroll to top */
            jQuery('body').isLoading('hide');
            jQuery('html, body').animate({ scrollTop: 0 }, '100');
        },
        error: function(data){
        }
    });

    return false;
}

/**
 * Ajax Pagination
 *
 * @param ajaxdoc
 * @param cultureKey
 */
function ajaxPagination(ajaxdoc, cultureKey)
{
    let linkid,
        pagenum,
        pagevar,
        pageAlias,
        perPage,
        target,
        ajaxurl,
        noajaxurl,
        pagevarLength,
        searchString,
        searchParams = '',
        searchParamsLength,
        siseaOffset,
        hrefAttr,
        srtingAfterPageVar,
        eventTypeParam,
        pagingPart,
        hash;

    jQuery('body').on('click', '.pageList a', function (e) {
        e.preventDefault();

        /* reset variables */
        pagenum = 0;
        pageAlias = '';
        eventTypeParam = '';
        hash = '';

        if(e.handled !== true && jQuery(this).attr('class') !== 'active') {
            jQuery('body').isLoading({
                'text': 'Načítání',    // loader CSS class
                'tpl': '<span class="isloading-wrapper %wrapper%">%text%<\/span>'
            });

            /* we need to get simplesearch offset */
            siseaOffset = jQuery(this).attr('attr-offset');

            /* get href to retrieve some variables */
            hrefAttr = jQuery(this).attr('href');

            /* everything after '#' */
            hash = hrefAttr.split('#');
            hash = '#' + hash[1];

            /* everything before '#' */
            hrefAttr = hrefAttr.split('#')[0];

            if (hrefAttr.indexOf('page') > 0) {
                /* everything after 'page' */
                srtingAfterPageVar = hrefAttr.split('page')[1];
                /* get link id */
                if (srtingAfterPageVar !== undefined) {
                    /* get page id */
                    linkid = srtingAfterPageVar.split('=')[0];
                    if (srtingAfterPageVar.split('=')[1] !== undefined && siseaOffset === undefined) {
                        /* get pagenum */
                        pagingPart = srtingAfterPageVar.split('&')[0];
                        pagenum = pagingPart.split('=')[1];

                        /* get eventType */
                        if (hrefAttr.indexOf('eventType') > 0) {
                            /* everything after 'eventType' */
                            let srtingAfterEventTypeParam = hrefAttr.split('eventType')[1];
                            let srtingAfterEventTypeEquals = srtingAfterEventTypeParam.split('=')[1];
                            let eventType = srtingAfterEventTypeEquals.split('&')[0];
                            eventTypeParam = '&eventType=' + eventType;
                        }
                    }
                } else {
                    /* set homepage id as default */
                    linkid = jQuery(this).attr('attr-pageid');
                }
            }

            /* get pagenum for simple search by offset and perPage variable */
            if (pagenum === 0 && siseaOffset !== undefined) {
                /* we need to get items per page */
                perPage = jQuery('#perPage').val();
                if (siseaOffset !== undefined) {
                    pagenum = Math.floor(siseaOffset / perPage) + 1;
                } else {
                    pagenum = 1;
                }
            }
            /* set pageNum alias for search page */
            if (siseaOffset !== undefined) {
                pageAlias = 'vyhledavani.html';
            }

            /* get search string and its length and get another pagenumClass_startString */
            searchString = jQuery('#hiddenSearchString').val();
            if (searchString !== '' && typeof searchString !== 'undefined') {
                searchParams = '&search=' + searchString + '&simplesearch_offset=' + siseaOffset;
            }
            searchParamsLength = searchParams.toString().length;

            /* get pagevar link part and its length */
            if (pagenum === '' || pagenum === 0) {
                pagenum = 1;
            }

            /* set pagevar param */
            pagevar = '&page' + linkid + '=' + pagenum;
            pagevarLength = pagevar.toString().length;

            /* target to load data */
            target = '#loading';

            /* final url */
            ajaxurl = ajaxdoc + pagevar + eventTypeParam + '&ajaxid=' + linkid + searchParams;

            /* call ajax */
            loadContent(ajaxurl, target, searchParamsLength, pagenum, pagevarLength, true);

            /* switch url */
            noajaxurl = window.location.href;
            if (searchString !== '' && typeof searchString !== 'undefined') {
                noajaxurl = window.location.origin + '/';
            }
            noajaxurl = noajaxurl.split('vyhledavani')[0];
            noajaxurl = noajaxurl.split('#')[0];
            noajaxurl = noajaxurl.split('?')[0];
            noajaxurl = noajaxurl + pageAlias + '?page' + linkid + '=' + pagenum + eventTypeParam + searchParams;
            switchUrl(noajaxurl + hash);

            /* set handle to true */
            e.handled = true;
        }

        return false;
    });
}

/**
 * Ajax Links
 *
 * @param ajaxdoc
 */
function ajaxLinks(ajaxdoc)
{
    let linkid,
        target,
        ajaxurl,
        noajaxurl,
        galleryId,
        galList,
        isClickedFromSidebar,
        isMxcDetail = false,
        mxcDetail,
        additionalParams = '',
        paramsLength,
        eventLink = false,
        hrefAttr,
        pagenum,
        srtingAfterPageVar,
        eventTypeParam,
        pagingPart;

    jQuery('body').on('click', 'a.ajaxlink', function (e) {
        e.preventDefault();

        /* reset variables */
        pagenum = 0;
        eventTypeParam = '';
        additionalParams = '';

        if (e.handled !== true) {
            jQuery('body').isLoading({
                'text': 'Načítání',    // loader CSS class
                'tpl': '<span class="isloading-wrapper %wrapper%">%text%<\/span>'
            });

            linkid = jQuery(this).attr('class').substr(15, 4);

            galleryId = jQuery(this).attr('data-gallery-id');
            /* set galleryParams for gallery component */
            if (typeof(galleryId) !== false && galleryId !== undefined) {
                additionalParams = '&galAlbum=' + galleryId;
            }

            galList = jQuery(this).attr('data-gallery-list');
            /* set additionalParams for gallery component */
            if (typeof(galList) !== false && galList !== undefined) {
                additionalParams = additionalParams + '&galList=1';
            }

            mxcDetail = jQuery(this).attr('data-mxc-detail-id');
            /* set pageNum alias for search page */
            if (typeof(mxcDetail) !== false && mxcDetail !== undefined && parseInt(mxcDetail) !== 0) {
                isMxcDetail = true;
                additionalParams = additionalParams + '&detail=' + mxcDetail;
            }

            isClickedFromSidebar = jQuery(this).attr('data-attr-sidebar-link');
            /* set fromSidebar param */
            if (typeof(isClickedFromSidebar) !== false && isClickedFromSidebar !== undefined && parseInt(isClickedFromSidebar) === 1) {
                isClickedFromSidebar = true;
                additionalParams = additionalParams + '&fromSidebar=1';
            }

            /* get href to retrieve some variables */
            hrefAttr = jQuery(this).attr('href');
            if (hrefAttr.indexOf('page') > 0) {
                /* everything after 'page' */
                srtingAfterPageVar = hrefAttr.split('page')[1];
                if (srtingAfterPageVar.split('=')[1] !== undefined) {
                    /* get pagenum */
                    pagingPart = srtingAfterPageVar.split('&')[0];
                    pagenum = pagingPart.split('=')[1];
                    /* get eventType */
                    if (hrefAttr.indexOf('eventType') > 0) {
                        /* everything after 'eventType' */
                        let srtingAfterEventTypeParam = hrefAttr.split('eventType')[1];
                        let srtingAfterEventTypeEquals = srtingAfterEventTypeParam.split('=')[1];
                        let eventType = srtingAfterEventTypeEquals.split('&')[0];
                        eventTypeParam = '&eventType=' + eventType;
                    }
                }
            }

            /* set eventLink variable */
            if (jQuery(this).parent().hasClass('eventLink')) {
                eventLink = true;
            }

            /* get additionalParams part length */
            paramsLength = additionalParams.length;

            /* target to load data */
            target = '#loading';

            /* final url */
            ajaxurl = ajaxdoc + '&ajaxid=' + linkid + eventTypeParam + additionalParams;

            /* call ajax */
            loadContent(ajaxurl, target, paramsLength, 0, 0, false, eventLink);

            /* switch url */
            noajaxurl = jQuery(this).attr('href');
            noajaxurl = noajaxurl + '#!' + linkid;
            switchUrl(noajaxurl);

            /* set eventLink to false */
            eventLink = false;

            /* set handle to true */
            e.handled = true;
        }

        return false;
    });
}

/**
 * Switch URL
 *
 * @param noajaxurl
 * @returns {boolean}
 */
export function switchUrl(noajaxurl)
{
    //to change the browser URL to the given link location;
    let url = noajaxurl;
    if(noajaxurl !== window.location){
        window.history.pushState({path:url}, '', url);
    }

    //stop refreshing to the page given in
    return false;
}

/**
 * get URL parameter
 *
 * @param name
 * @returns {string | number}
 */
export function urlParam(name)
{
    let vars = {};
    let parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });

    return vars[name];
}



$(document).ready(function() {
    //backbutton support;
    historyPushState('ajaxcontent.html', 'cs');

    //ajaxlinks
    ajaxLinks('ajaxcontent.html');
    ajaxPagination('ajaxcontent.html', 'cs');
});