import 'bootstrap';
import 'magnific-popup';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'bootstrap-datepicker';
//import 'bootstrap-select';

// dynamicly loaded module to separate it into chunk and specify chunkName
// import( /*webpackChunkName: 'bootstrap'*/'bootstrap');

// dynamicly loaded module to separate it into chunk and specify chunkName
// import( /*webpackChunkName: 'magnific-popup'*/'magnific-popup');

// dynamicly loaded module to separate it into chunk and specify chunkName
// import( /*webpackChunkName: 'bootstrap.bundle'*/'bootstrap/dist/js/bootstrap.bundle.js');

// dynamicly loaded module to separate it into chunk and specify chunkName
//import( /*webpackChunkName: 'bootstrap-datepicker'*/'bootstrap-datepicker');

// dynamicly loaded module to separate it into chunk and specify chunkName
import( /*webpackChunkName: 'bootstrap-select'*/'bootstrap-select');

import ResponsiveBootstrapToolkit from 'responsive-toolkit';

import {SchedulePlan} from './../../../themeComponents/timetables/js/index.js';

import '../scss/app.scss';

/* variables */
let didScroll;
// let lastScrollTop = 0;
// let delta = 5;
// let navbarHeight = 181;
let currentLang = $('html').attr('lang');
let timetableEement = $('.cd-schedule');

/* to init what we need */
export function index() {
    // on scroll, let the interval function know the user has scrolled
    $(window).scroll(function(event)
    {
        didScroll = true;
    });

    /* navbar show submenu */
    navbarHover();

    /* hoverItem */
    //hoverItem('.hoverIt', 300);

    // run hasScrolled() and reset didScroll status
    setInterval(function() {
        if (didScroll) {
            didScroll = false;
        }
    }, 250);


    //window.popper = Popper;
    //$('.myselect').selectpicker();

    // show_menu button
    triggerHamburgerIcon();

    // switch sender type in "confirmation about gift form"
    switchSenderType();

    // switch sender by in "confirmation about gift form"
    switchSendInfoByType();

    // init magnific popup
    if ($('.thumb_link').length > 0) {
        initMagnificPopup();
    }

    // show Info Modal with class showContentInfoModal
    $('.showContentInfoModal').on('click', function (e) {
        e.preventDefault();
        $('#contentInfoModal').modal('show');
    });

    /* init schedule */
    if (timetableEement.length) {
        let scheduleObject = new SchedulePlan(timetableEement);
        scheduleObject.initSchedule();
    }

    // //init dl-menu
    // $('#dl-menu').dlmenu({
    //     animationClasses : { classin : 'dl-animate-in-2', classout : 'dl-animate-out-2' }
    // });

    // bootstrap-datepicker language
    if ($('.datepicker_cs').length > 0 || $('#day_of_birth_kid').length > 0) {
        $.fn.datepicker.defaults.language = 'cs';
        $.fn.datepicker.dates['cs'] = {
            days: ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
            daysShort: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
            daysMin: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
            months: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
            monthsShort: ['Led', 'Úno', 'Bře', 'Dub', 'Kvě', 'Čvn', 'Čvc', 'Srp', 'Zář', 'Říj', 'Lis', 'Pro'],
            today: 'Dnes',
            clear: 'Vyčistit',
            format: 'dd.mm. yyyy',
            titleFormat: 'MM yyyy', /* Leverages same syntax as 'format' */
            weekStart: 1
        };
    }
    if ($('.datepicker_cs').length > 0) {
        $('.datepicker_cs').datepicker({
            language: 'cs'
        });
    }
    if ($('#day_of_birth_kid').length > 0) {
        $('#day_of_birth_kid').datepicker({
            language: 'cs',
            startView: 'years'
        });
    }
}

/*
*
* CUSTOM FUNCTIONS
*
*/

/* Hover Item */
function hoverItem(targetBox, speed)
{
    $(targetBox).hover(function () {
        if($(this).find('.link_more a')) {
            $(this).find('.link_more a').addClass('active');
        }
        $(this).animate({
            'opacity':'0.75',
            'top':'-10'
        });
    }, function() {
        $('.link_more a.active').removeClass('active');
        $(this).animate({
            'opacity':'1',
            'top':'0'
        }, speed);
    });
    $(targetBox).on('click', function (e) {
        e.preventDefault();
        if(e.handled !== true) {
            if($(this).find('.link_more a')) {
                window.location.href = $(this).find('.link_more a').attr('href');
            }
            else {
                return;
            }
            e.handled = true;
        }

        return false;
    });
}

/* bootstrap navbar hover */
function navbarHover()
{
    $('ul.nav li.dropdown').hover(
        function() {
            $(this).find('.dropdown-menu').fadeIn(300);
        },
        function() {
            $(this).find('.dropdown-menu').fadeOut(300);
        }
    );
}

/* init DL menu */
export function initDlMenu()
{
    $('#dl-menu').dlmenu({
        animationClasses : { classin : 'dl-animate-in-2', classout : 'dl-animate-out-2' }
    });
}

/* add class when page is scrolled */
function triggerHamburgerIcon()
{
    let menu = $('.dl-menu');
    $('.hamburger').on('click', function(e)
    {
        e.preventDefault();
        if (menu.hasClass('dl-menuopen')) {
            $('.hamburger').removeClass('is-active');
        } else {
            $('.hamburger').addClass('is-active');
        }
    });
}

/* swtch sender type in confirm gift form */
export function switchSenderType()
{
    let form = $('#confirmgiftform');
    let senderTypeInput = $('#sender_type').find('input[name="sender_type"]');
    let personalTypeBlock = form.find('.block-personal');
    let companyTypeBlock = form.find('.block-company');
    let val;

    $(senderTypeInput).on('change', function(e)
    {
        $(this).prop('checked',true);
        if( $(this).is(":checked") ){ // check if the radio is checked
            val = $(this).val(); // retrieve the value
        }
        if (val === 'osoba') {
            companyTypeBlock.removeClass('is-active');
            personalTypeBlock.addClass('is-active');
        } else {
            personalTypeBlock.removeClass('is-active');
            companyTypeBlock.addClass('is-active');
        }
    });
}

/* swtch sender type in confirm gift form */
export function switchSendInfoByType()
{
    let form = $('#confirmgiftform');
    let senderTypeInput = $('#mail_type').find('input[name="mail_type"]');
    let senderTypeCheckbox = $('#mail_type').find('input[name="additional_address"]');
    let additionalAddressCheckbox = form.find('#checkbox-block-additional-address');
    let additionalAddressTypeBlock = form.find('.block-additional-address');
    let val;
    $(senderTypeInput).on('change', function(e)
    {
        $(this).prop('checked',true);
        if( $(this).is(":checked") ){ // check if the radio is checked
            val = $(this).val(); // retrieve the value
        }
        if (val === 'email') {
            // additionalAddressTypeBlock.removeClass('is-active');
            senderTypeCheckbox.prop('checked', false);
            senderTypeCheckbox.attr('value', ''); // set value
            additionalAddressCheckbox.removeClass('show_transition');
            additionalAddressCheckbox.addClass('hide_transition');
            additionalAddressTypeBlock.removeClass('is-active');
        } else {
            // additionalAddressTypeBlock.addClass('is-active');
            additionalAddressCheckbox.addClass('show_transition');
            additionalAddressCheckbox.removeClass('hide_transition');
        }
    });
    $(senderTypeCheckbox).on('change', function(e)
    {
        if( $(this).is(":checked") ){ // check if the checkbox is checked
            val = 'address_2';
            $(this).attr('value', 'address_2'); // set value
            additionalAddressTypeBlock.addClass('is-active');
        } else {
            $(this).attr('value', ''); // unset value
            additionalAddressTypeBlock.removeClass('is-active');
        }
    });
}

/**
 *
 */
function initMagnificPopup()
{
    $('.thumb_link').magnificPopup({
        type: 'image',
        zoom: {
            enabled: true, // By default it's false, so don't forget to enable it
            duration: 300, // duration of the effect, in milliseconds
            easing: 'ease-in-out', // CSS transition easing function

            // The "opener" function should return the element from which popup will be zoomed in
            // and to which popup will be scaled down
            // By defailt it looks for an image tag:
            opener: function(openerElement) {
                // openerElement is the element on which popup was initialized, in this case its <a> tag
                // you don't need to add "opener" option if this code matches your needs, it's defailt one.
                return openerElement.is('img') ? openerElement : openerElement.find('img');
            }
        },
        gallery:{
            enabled:true
        }
    });

    /* switch language of magnificPopup */
    switch (currentLang) {
        case 'cs':
            $.extend(true, $.magnificPopup.defaults, {
                tClose: 'Zavřít',
                tLoading: 'Nahrávám...',
                gallery: {
                    tPrev: 'Předchozí',
                    tNext: 'Další',
                    tCounter: '%curr% z(e) %total%'
                },
                image: {
                    tError: '<a href="%url%">Obrázek</a> nelze nahrát.'
                },
                ajax: {
                    tError: 'Der <a href="%url%">Obsah</a> nelze nahrát.'
                }
            });

            break;
        default:
        // english already included
    }
}

(function($, viewport){

    let visibilityDivs = {
        'xs': $('<div class="d-xs-block d-sm-none d-md-none d-lg-none d-xl-none"></div>'),
        'sm': $('<div class="d-none d-sm-block d-md-none d-lg-none d-xl-none"></div>'),
        'md': $('<div class="d-none d-md-block d-sm-none d-lg-none d-xl-none"></div>'),
        'lg': $('<div class="d-none d-lg-block d-sm-none d-md-none d-xl-none"></div>'),
        'xl': $('<div class="d-none d-xl-block d-sm-none d-md-none d-lg-none"></div>'),
    };

    viewport.use('custom', visibilityDivs);

    $(document).ready(function() {
        // init app and main ajax functions
        index();

        // init dl-menu (not in ajax response, init it here)
        initDlMenu();

        // Executes in SM after resize
        // $(window).resize(
        //     viewport.changed(function() {
        //         console.log('Current breakpoint: ', viewport.current());
        //         if (viewport.is('<=sm')) {
        //             // $('.cd-schedule').css({
        //             //     height: 'auto'
        //             // });
        //         }
        //     })
        // );
    });
})(jQuery, ResponsiveBootstrapToolkit);

// if (module.hot) {
//     module.hot.accept('../scss/app.scss', () => {
//         const baseStyle = window.document.getElementById('js-style-core');
//         baseStyle.setAttribute('href', '/assets/templates/zstrnka2018/themeCore/css/app.css?v=' + new Date().valueOf);
//     });
// }