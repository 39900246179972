import '../scss/footer.scss';
import '../scss/responsive.scss';

/**
 * show Rss Menu Items
 *
 */
function showRssItems()
{
    let btn = $('.btn-rss > a');
    let rssMenu = $('.rss_menu');

    btn.on('click', function (e) {
        e.preventDefault();
        if (rssMenu.hasClass('active')) {
            $(this).removeClass('active');
            rssMenu.removeClass('active').hide('100');
        } else {
            $(this).addClass('active');
            rssMenu.addClass('active').show('100');
        }
    });
}

$(document).ready(function() {
    showRssItems();
});