//require('_themeCore/3-0_ajax/js/index.js');
//require('_themeCore/1-0_is_loading/js/index.js');
//import {switchUrl} from "./../../../themeCore/3-0_ajax/js/index";
import '../scss/search.scss';
import '../scss/responsive.scss';

/**
 * Ajax Submit Search
 *
 * @param ajaxdoc
 */
export function ajaxSubmitSearch(ajaxdoc)
{
    let searchForm = jQuery('#searchform');
    let searchInputText = searchForm.find('.form_input_txt');
    searchInputText.on('click', function (e) {
        clearSearch();
    });

    searchForm.find('.form_submit').on('click', function (e) {
        //e.preventDefault();
        if (e.handled !== true) {
            jQuery('body').isLoading({
                'text': 'Načítání',    // loader CSS class
                'tpl': '<span class="isloading-wrapper %wrapper%">%text%<\/span>'
            });
            jQuery.ajax({
                type: 'POST',
                cache: false,
                //async: false, //disable isLoading functionality
                url: ajaxdoc + '&ajaxid=21',
                dataType: 'html',
                data: jQuery('#filterform').serialize(),
                success: function (data) {
                    jQuery('#loading').html(data);
                    jQuery('body').isLoading('hide');
                    jQuery('.breadcrumb').remove();
                    //switch url
                    let noajaxurl = window.location.origin + '/vyhledavani.html';
                    let searchString = jQuery('#hiddenSearchString').val();
                    if (searchString !== '' && typeof searchString !== 'undefined') {
                        noajaxurl = noajaxurl + '&search=' + searchString;
                    }
                    switchUrl(noajaxurl);
                    e.handled = true;
                },
                error: function () {
                }
            });
            e.handled = true;
        }

        return false;
    });
}

/**
 * Clear Input Search
 */
export function clearSearch()
{
    jQuery('#filterform').find('.form_input_txt').val('');
    //jQuery('#searchform').find('.form_submit').hide();
}

/**
 * Switch URL
 *
 * @param noajaxurl
 * @returns {boolean}
 */
function switchUrl(noajaxurl)
{
    //to change the browser URL to the given link location;
    let url = noajaxurl;
    if(noajaxurl !== window.location){
        window.history.pushState({path:url}, '', url);
    }

    //stop refreshing to the page given in
    return false;
}

/**
 * init
 */
$(document).ready(function() {
    //clearSearch
    clearSearch();
    //Ajax Submit Search
    ajaxSubmitSearch('ajaxcontent.html');
});